import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

/** SCSS */
import './sass/base/init_sizing.scss'
import './sass/base/typography.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
